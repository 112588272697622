var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-form-group',{staticClass:"c-input-wrap c-input-group",attrs:{"id":_vm.id + '-group'}},[_c('div',{staticClass:"c-input",attrs:{"placeholder":""}},[_c('div',{staticClass:"c-input__label",class:{ 'c-input__label-active': _vm.inputActive || _vm.localValue != '' }},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]),_c('validation-provider',{attrs:{"rules":_vm.disabled ? '' : _vm.type + _vm.dopRules + (_vm.isRequired ? '|required' : ''),"mode":_vm.mode,"name":_vm.id},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [((_vm.type.includes('password')
            ? _vm.passwordToggle
              ? 'text'
              : 'password'
            : _vm.inputType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"c-input__input",class:{
          'c-input__input-failing': errors[0],
          'c-input__input-outline': _vm.focused & _vm.outline,
        },attrs:{"id":_vm.id,"disabled":_vm.disabled,"inputmode":_vm.inputMode,"required":_vm.isRequired,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.localValue)?_vm._i(_vm.localValue,null)>-1:(_vm.localValue)},on:{"input":_vm.onChangeValue,"focus":() => {
            _vm.inputActive = true;
            _vm.setMask(_vm.localValue);
            _vm.focused = true;
          },"blur":function($event){_vm.focused = false},"change":function($event){var $$a=_vm.localValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.localValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.localValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.localValue=$$c}}}}):((_vm.type.includes('password')
            ? _vm.passwordToggle
              ? 'text'
              : 'password'
            : _vm.inputType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"c-input__input",class:{
          'c-input__input-failing': errors[0],
          'c-input__input-outline': _vm.focused & _vm.outline,
        },attrs:{"id":_vm.id,"disabled":_vm.disabled,"inputmode":_vm.inputMode,"required":_vm.isRequired,"type":"radio"},domProps:{"checked":_vm._q(_vm.localValue,null)},on:{"input":_vm.onChangeValue,"focus":() => {
            _vm.inputActive = true;
            _vm.setMask(_vm.localValue);
            _vm.focused = true;
          },"blur":function($event){_vm.focused = false},"change":function($event){_vm.localValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"c-input__input",class:{
          'c-input__input-failing': errors[0],
          'c-input__input-outline': _vm.focused & _vm.outline,
        },attrs:{"id":_vm.id,"disabled":_vm.disabled,"inputmode":_vm.inputMode,"required":_vm.isRequired,"type":_vm.type.includes('password')
            ? _vm.passwordToggle
              ? 'text'
              : 'password'
            : _vm.inputType},domProps:{"value":(_vm.localValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.localValue=$event.target.value},_vm.onChangeValue],"focus":() => {
            _vm.inputActive = true;
            _vm.setMask(_vm.localValue);
            _vm.focused = true;
          },"blur":function($event){_vm.focused = false}}}),(_vm.type.includes('password'))?_c('button',{staticClass:"password-toggle",class:{ 'password-toggle-active': _vm.passwordToggle },attrs:{"type":"button"},on:{"click":function($event){_vm.passwordToggle = !_vm.passwordToggle}}}):_vm._e(),(errors[0])?_c('div',{staticClass:"c-input__error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])}),(_vm.suggestionText)?_c('div',{staticClass:"c-input__suggestion-text"},[_vm._v(" "+_vm._s(_vm.suggestionText)+" ")]):_vm._e()],1),(_vm.suggestions.length > 0)?_c('div',{staticClass:"suggestions",attrs:{"id":"suggestions"}},_vm._l((_vm.suggestions),function(suggestion,ind){return _c('div',{key:ind,on:{"click":function($event){return _vm.chooseSuggestValue(suggestion.value)}}},_vm._l((_vm.getColorOfSuggestion(suggestion.value)),function(item,strInd){return _c('span',{key:strInd},[_c('span',{class:{ 'text-danger': item.colored }},[_vm._v(_vm._s(item.value))])])}),0)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }