import axiosIns from '@/libs/axios'

export default class User {
    static async sendActivateCode(accessToken) {
        return await axiosIns.post('/user/get-activate-code', { access_token: accessToken })
    }

    static async activate(request) {
        return (await axiosIns.post('/user/activate', request)).data
    }

    static async editPhone(request) {
        return (await axiosIns.post('/user/edit-phone', request)).data
    }

    static async logOut() {
        return (await axiosIns.post('/user/logout')).data
    }
}
