// eslint-disable-next-line object-curly-newline
import { globalTest } from './hypotheses'

export function checkFileType(type) {
    switch (type) {
    case 'image/jpeg':
    case 'image/jpg':
    case 'image/png':
    case 'image/gif':
    case 'image/bmp':
    case 'image/webp':
        return true
    default:
        return false
    }
}
export function getRegName(index) {
    switch (index) {
    case (0):
        return 'first'
    case (1):
        return 'second'
    case (2):
        return 'third'
    case (3):
        return 'activate'
    case (4):
        return 'fill'
    case (5):
        return 'application'
    default:
    }
}
export function getLastRegIndex() {
    const lastRegIndex = localStorage.getItem('lastRegIndex')

    if (lastRegIndex != null) {
        const test = globalTest()
        return Number((test === 'B' && lastRegIndex === '2') ? '3' : localStorage.getItem('lastRegIndex'))
    }
    return null
}
export function getRegIndex(routerName) {
    switch (routerName) {
    case ('first'):
        return 0
    case ('second'):
        return 1
    case ('third'):
        return 2
    case ('activate'):
        return 3
    case ('fill'):
        return 4
    case ('application'):
        return 5
    default: return -1
    }
}
