import {
    computed,
    onMounted,
    onUnmounted,
    ref,
} from 'vue'

export const useBreakpoints = () => {
    const windowWidth = ref(window.innerWidth)

    const onWidthChange = () => { windowWidth.value = window.innerWidth }
    onMounted(() => window.addEventListener('resize', onWidthChange))
    onUnmounted(() => window.removeEventListener('resize', onWidthChange))

    const type = computed(() => {
        if (windowWidth.value < 550) return 'xs'
        if (windowWidth.value >= 550 && windowWidth.value < 1200) return 'md'
        if (windowWidth.value >= 1200 && windowWidth.value < 1440) return 'lg'
        if (windowWidth.value >= 1440) return 'xl'
        return null // This is an unreachable line, simply to keep eslint happy.
    })

    const width = computed(() => windowWidth.value)

    return { width, type }
}

export const userPosition = () => {
    const positionY = ref()
    const clientHeight = ref()
    clientHeight.value = document.documentElement.clientHeight
    window.addEventListener('scroll', () => {
        positionY.value = window.scrollY
    }, false)
    positionY.value = parseInt(positionY.value)
    return { positionY, clientHeight }
}
export const getOffset = el => {
    if (!el) {
        return
    }
    const rect = el.getBoundingClientRect()
    return {
        left: parseInt(rect.left + window.scrollX),
        top: parseInt(rect.top + window.scrollY),
    }
}
