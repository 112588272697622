<template>
  <div>

    <div
      v-if='!isLoading'
      :id='id'
      class='c-button'
      :class='{"c-button-outlined": type === "outlined", "c-button-disabled": disabled}'
    >
      <button

        type='button'
        :disabled='disabled'
        @click='$emit("onClick")'
      >
        <div>{{ title }}</div>
      </button>
    </div>

    <div
      v-else
      class='c-button'
      :class='{"c-button-outlined": type === "outlined"}'
    >
      <button
        type='button'
        :style='{width: buttonswidth}'
      >
        <b-spinner
          variant='white'
        />
      </button>

    </div>
  </div>

</template>

<script>
import { BSpinner } from 'bootstrap-vue'

export default {
    components: {
        BSpinner,
    },

    props: {
        title: {
            type: String,
            require: true,
            default: '',
        },

        isLoading: {
            type: Boolean,
            require: false,
            default: false,
        },
        type: {
            type: String,
            require: false,
            default: 'fill',
        },
        disabled: {
            type: Boolean,
            require: false,
            default: false,
        },
        id: {
            type: String,
            require: true,
            default: 'main-button',
        },
    },
    data() {
        return {
            buttonswidth: '150px',
        }
    },
    mounted() {
        if (document.getElementById(this.id) != null) {
            this.buttonswidth = document.getElementById(this.id).offsetWidth + 'px'
        }
    },

}
</script>

 <style lang='scss'>
@import "@core/scss/base/bootstrap-extended/_variables.scss";
.c-button {
  width: fit-content;

  > button {
    width: fit-content;
    color: $on-secondary-surface;
    font-family: "ConsolaMonoBold", sans-serif;
    font-size: 24px;
    text-align: center;
    cursor: pointer;
    background: $secondary-surface;
    border: none;
    padding: 20px 35px 24px;
    line-height: 24px;
    transition: all 0.4s ease-out;

    @media only screen and (max-width: 1600px) {
      font-size: 21px;
      line-height: 21px;
    }
    @media only screen and (max-width: 1440px) {
      font-size: 16px;

      padding: 14px 34px 19px;
      line-height: 21px;
    }
    @media only screen and (max-width: 425px) {
      font-size: 14px;
      padding: 10px 25px 15px;
    }
    > div {
      white-space: nowrap;
    }
  }
  &-disabled {
    > button {
      background: $secondary-surface;
      opacity: 0.6;
    }
  }
  &-outlined {
    > button {
      background: $on-secondary-surface;
      > div {
        color: $secondary-surface;
      }
    }
  }
}
</style>
