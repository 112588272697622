<template>
  <div>
    <div
      class='sidebar-header'
    >
      <div
        v-if='!signIn'
        class='sidebar-header__head'
        aria-label='open login form'
        @click='loginModalToggle'
        @keypress='loginModalToggle'
      />

      <a
        href='/'
        class='sidebar-header__logo'
      >
        <span>One Click Money</span>
      </a>
      <button
        type='button'
        class='close-menu'
        aria-label='close menu'
        @click='$emit("sideBarToggle")'
      >
        <span class='line-c line-c1' /><span class='line-c line-c2' />
      </button>
    </div>

  </div>
</template>

<script>
import { getCookie, redirectToMain } from '@utils'
import { VBToggle } from 'bootstrap-vue'
import Vue from 'vue'

Vue.directive('b-toggle', VBToggle)
export default {
    props: {
        color: {
            type: String,
            require: false,
            default: '',
        },
    },
    data() {
        return {
            signIn: getCookie('loanWasTaken') === 'true',
        }
    },
    methods: {
        logOut() {
            redirectToMain({ log: 'redirect_already_registred', path: this.$route.path })
        },
        loginModalToggle(value) {
            if (value) {
                this.$router.push({ hash: '#signin' })
            } else {
                this.$router.push({ hash: '' })
            }
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/base/bootstrap-extended/_variables.scss";

.sidebar-header {
  display: flex;
  justify-content: space-between;
  background-color: $secondary-surface;
  z-index: 999;
  height: 62px;
  margin-left: 0 !important;
  width: 100%;
  padding: 20px 48px 0;

  @media screen and (max-width: 425px) {
    padding: 10px 25px 0;
  }
  &__logo {
    width: 280px;
    height: 40px;
    display: block;
    background: url(@/assets/images/ocm/logo/full-bb.svg) no-repeat center;
    > span {
      opacity: 0;
    }

    @media only screen and (max-width: 640px) {
      width: 240px;
      margin-top: 8px;
    }

    @media only screen and (max-width: 425px) {
      background: url(@/assets/images/ocm/logo/short-bb.svg) no-repeat center;
      background-size: contain;
      width: 90px;
      height: 30px;
      align-self: flex-end;
    }
  }
  &__head {
    background: url(@/assets/images/ocm/components/header/regestration-head-white.svg)
      no-repeat center;
    background-size: contain;
    width: 40px;
    height: 40px;
    position: relative;
    padding: 0;
    margin-top: 2px;
    @media only screen and (max-width: 425px) {
      width: 33px;
      height: 33px;
      margin-top: 11px;
    }
  }

  .open-menu {
    width: 31px;
    height: 29px;
    position: relative;
    float: right;
    margin: 9px 0 0;
    border: none;
    background: 0 0;
  }

  .close-menu {
    width: 40px;
    height: 40px;
    position: relative;
    background: 0 0;
    margin: 8px 0 0 -4px;
    @media only screen and (max-width: 425px) {
      width: 33px;
      height: 28px;
    }
    .line-c {
      width: 100%;
      height: 3px;
      background-color: $on-secondary-surface;
      position: absolute;
      top: 12px;
    }

    .line-c1 {
      transform: rotate(45deg);
      left: 0;
    }

    .line-c2 {
      transform: rotate(-45deg);
      right: 0;
    }
  }
}
</style>
