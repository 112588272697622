<template>
  <div>
    <app-skeleton v-if='loading' />
    <div
      v-else
      id='app'
      :key='layout'
      :class='{"app-new": layout === "new-layout-vertical", "app": layout !== "new-layout-vertical"}'
    >
      <div
        :class='{"app__wrap-new": layout == "new-layout-vertical"}'
      >

        <c-sidebar
          v-if='displaySideBar && layout == "layout-vertical"'
          :side-bar-toggle='sideBarToggle'
          :sign-in='signIn'
          @showCallBackModal='callBack = true'
        />
        <c-new-sidebar
          v-if='displaySideBar && layout == "new-layout-vertical"'
          :side-bar-toggle='sideBarToggle'
          :sign-in='signIn'
        />
        <!-- <login-modal
          :toggle='loginToggle'
          @closeModal='loginModalToggle(false)'
          @openModal='loginModalToggle(true)'
        /> -->

        <portal-target name='app-page' />
        <router-view v-if='withoutLayout.includes($route.name)' />

        <component
          :is='layout'
          v-else
        >
          <router-view />
        </component>
      </div>
    </div>

    <c-modal
      v-cloak
      key='call-back'
      :toggle='callBack'
      @closeModal='() => {}'
      @openModal='() => {}'
    >
      <template #content>

        <call-back-modal />
      </template>
    </c-modal>

  </div>
</template>

<script>
import useInitialization from '@/helper/registration/useInitialization'
import Firebase from '@/libs/firebase/firebase'
import CModal from '@components/CModal.vue'
import CSidebar from '@components/CSidebar.vue'
import CallBackModal from '@components/modals/CallBackModal.vue'
import LoginModal from '@components/modals/LoginModal.vue'
import CNewSidebar from '@new-components/CSidebar.vue'
import { $themeBreakpoints, $themeColors } from '@themeConfig'
import { getCookie } from '@utils'
import { useCssVar } from '@vueuse/core'
import AppSkeleton from './views/landing/AppSkeleton.vue'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const NewLayoutVertical = () => import('@/layouts/vertical/NewLayoutVertical.vue')
import("@core/scss/base/plugins/vue-slider/vue-slider-custom.scss")

export default {
    components: {
        LayoutVertical,
        NewLayoutVertical,
        LoginModal,
        CSidebar,
        CNewSidebar,
        AppSkeleton,
        CModal,
        CallBackModal,
    },

    data() {
        return {
            utmSource: null,
            utmMedium: null,
            utmCampaign: null,
            withoutLayout: ['landing'],
            loginToggle: false,
            signIn: getCookie('loanWasTaken'),
            sideBarToggle: false,
            part: null,
            leanTestId: null,
            loading: true,
            layout: null,
            displaySideBar: false,
            callBack: false,
        }
    },

    computed: {
        contentLayoutType() {
            return this.$store.state.appConfig.layout.type
        },
    },
    watch: {
        '$route.hash': {
            handler(hash) {
                if (hash === '#signin') {
                    this.loginToggle = true
                } else {
                    this.loginToggle = false
                }
            },
            deep: true,
            immediate: true,
        },
    },

    async beforeCreate() {
        document.documentElement.setAttribute('dir', 'ltr')
        document.documentElement.setAttribute('lang', 'RU')
        const metaRobots = document.createElement('meta')
        metaRobots.setAttribute('name', 'robots')
        metaRobots.setAttribute('content', 'noyaca')
        document.head.prepend(metaRobots)
        const metaDescription = document.createElement('meta')
        metaDescription.setAttribute('name', 'description')
        metaDescription.setAttribute('content', 'Сервис OneClickMoney выдает онлайн займы на карту и киви. Предоставляет мгновенные микрозаймы и экспресс-кредиты через интернет быстро, срочно и круглосуточно.')
        document.head.prepend(metaDescription)
    },

    async created() {
        if (!localStorage.getItem('userIsInited')) {
            const { initUser } = useInitialization()
            await initUser()
            this.setLayout()

            this.loading = false
        } else {
            this.setLayout()
            this.loading = false
        }
        await this.setup()
    },
    mounted() {
        this.displaySideBar = true
    },
    methods: {
        setLayout() {
            const userIsInited = localStorage.getItem('userIsInited')

            if (userIsInited) {
                this.part = localStorage.getItem('part')
                this.leanTestId = localStorage.getItem('lean_test_id')
                this.layout = (this.part === 'B' && this.$route.path === '/' && this.leanTestId === 'OCM_AB_TEST_NEW_UX_UI_GLOBAL') ? `new-layout-vertical` : `layout-vertical`
            } else {
                this.layout = null
            }
        },
        async setup() {
            await Firebase.initializeApp()

            // Set colors in theme
            const colors = [
                'primary',
                'secondary',
                'success',
                'info',
                'warning',
                'danger',
                'light',
                'dark',
            ]

            // eslint-disable-next-line no-plusplus
            for (let i = 0, len = colors.length; i < len; i++) {
                $themeColors[colors[i]] = useCssVar(
                    `--${colors[i]}`,
                    document.documentElement,
                ).value.trim()
            }

            // Set Theme Breakpoints
            const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

            // eslint-disable-next-line no-plusplus
            for (let i = 0, len = breakpoints.length; i < len; i++) {
                $themeBreakpoints[breakpoints[i]] = Number(
                    useCssVar(
                        `--breakpoint-${breakpoints[i]}`,
                        document.documentElement,
                    ).value.slice(0, -2),
                )
            }
        },

        loginModalToggle(value) {
            if (value) {
                this.$router.push({ hash: '#signin' })
            } else {
                this.$router.push({ hash: '' })
            }
        },
        openLoginForm() {
            this.$router.push({ hash: 'signin' })
        },
        getLoan() {
            this.$router.push({ name: 'first' })
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-open {
  .app {
    overflow: hidden;
  }
}
.app {
  color: #333;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  height: 100%;
  position: relative;
  &__wrap {
    width: 100%;

    padding: 0 60px 0;
    @media only screen and (max-width: 1200px) {
      padding: 0;
    }
  }
}
.app-new {
  color: #333;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  position: relative;
  max-width: 100%;
  &__wrap {
    width: 100%;
    padding: 0;
  }
}
</style>
