/* eslint-disable no-restricted-syntax */
import Logs from '@/api/logs'
import Register from '@/api/register'
import User from '@/api/user'
import {
    redirectToMain,
    sendGA4,
    sendLogs,
    showToast,
    testUnitHypothesis,
} from '@utils'
import { ref } from 'vue'
import {
    accessToken,
    agreementCheckboxes,
    form,
    formToSubmit,
    password,
    registrationFormfirst,
    registrationFormsecond,
    registrationFormthird,
    steps,
    systemPayments,
} from './forms'

import {
    preprocessing, setErrorsAfterSendingForm, setErrorsBeforeSendingForm,
} from './processing'
import { validateForm } from './validation'

const isLoading = ref(false)
const addPaymentLoading = ref(false)

export default function useRegistration() {
    // *====== обращения к api
    async function addPaymentSystem() {
        if (form.third.addPaySystem !== 'yandex' && !(registrationFormthird.value.fields.card_number.valid && registrationFormthird.value.fields.card_exp.valid)) {
            return false
        }

        addPaymentLoading.value = true
        const paymentSystem = systemPayments.data.find(el => el.value.includes(form.third.addPaySystem))
        if (!paymentSystem) {
            addPaymentLoading.value = false
            return false
        }

        return await Register.addPaySystem({
            access_token: localStorage.getItem('access_token'),
            id: paymentSystem.id,
            paySystem: paymentSystem.value,
            number: paymentSystem.number.replace(/[\s-]/g, ''),
            titleNumber: paymentSystem.number.replace(/[\s-]/g, ''),
            expiredData: paymentSystem.exp,
            typeData: form.third.addPaySystem,
            isSelected: true,
            contactPoint: null,
            contactTown: null,
            contactAdress: null,
        })
            .then(async res => {
                if (res.statusCode === 200) {
                    localStorage.setItem('paymentSystemWasSelected', true)
                    addPaymentLoading.value = false
                    // const test = globalTest()

                    showToast({ text: 'Карта успешно сохранена', status: 'success' })

                    return true
                }
                handleaddPaySystem('Ошибка привязки карты')
                return false
            })
            .catch(e => {
                addPaymentLoading.value = false
                handleaddPaySystem(e)
                return false
            })
    }

    async function handleaddPaySystem(err) {
        showToast({ text: 'Ошибка привязки карты', status: 'error' })
        await sendLogs('third', `reg_error: ${err}`)
    }

    async function sendForm(index) {
        isLoading.value = true
        const step = steps[index].shortName
        const res = await validateForm(steps[index].shortName)
            .then(async success => {
                setErrorsBeforeSendingForm(index)
                if (!success) {
                    return false
                }
                form[step].access_token = localStorage.getItem('access_token')
                if (step === 'first') {
                    form[step].lean_test_id = localStorage.getItem('lean_test_id')
                }
                preprocessing(step)

                if (step === 'third') {
                    if (!localStorage.getItem('paymentSystemWasSelected')) {
                        try {
                            const addPaySystemStatus = await addPaymentSystem()
                            if (!addPaySystemStatus) {
                                return
                            }
                        } catch (e) {
                            console.log(e)
                        }
                    }
                }

                const result = await Register[step](formToSubmit[step])

                let logString = `этап - ${step}, ответ: ${JSON.stringify(result)}`

                if (step === 'first') {
                    logString += `запрос: ${formToSubmit[step]?.mobile_phone}`
                } else if (step === 'second') {
                    logString += `запрос: ${formToSubmit[step]?.passport_number}`
                }
                await Logs.send({
                    access_token: localStorage.getItem('access_token'), name: 'sendForm', log: logString,
                })


                if (result.statusCode !== 200) {
                    setErrorsAfterSendingForm(result.error ?? result.body.message, result.statusCode, step)

                    return false
                }
                await sendGA4('form-sent', `${steps[index].shortName}-form-sent-success`)
                accessToken.value = result.access_token
                localStorage.setItem('access_token', result.access_token)

                if (step === 'third' && testUnitHypothesis() === 'OCM_HIDDEN_DOP_PHONE') {
                    await sendLogs('lean_test_number', formToSubmit[step].dop_phone)
                    await sendLogs('hidden_dop_phonee', formToSubmit[step].dop_phone)
                }

                localStorage.setItem('lastRegIndex', index)

                return true
            })
            .catch(e => {
                setErrorsAfterSendingForm(e, 0, steps[index].shortName)
                return false
            })

        isLoading.value = false
        return res
    }

    async function logOut(path) {
        await User.logOut()
        await sendLogs('redirect', `logout:${path}`)
        localStorage.clear()
        redirectToMain({ log: 'redirect_already_registred' })
    }

    return {
        accessToken,
        form,
        steps,
        systemPayments,

        sendForm,
        addPaymentSystem,
        setErrorsAfterSendingForm,
        registrationFormfirst,
        registrationFormsecond,
        registrationFormthird,
        agreementCheckboxes,
        password,
        isLoading,
        addPaymentLoading,
        logOut,
    }
}
