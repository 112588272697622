export const validatorPositive = value => {
    if (value >= 0) {
        return true
    }
    return false
}

export const validatoMobilePhone = phone => {
    /* eslint-disable no-useless-escape */
    const localPhone = phone.replace(/[\s-()]/g, '')
    const regExp = /^((\+79))[\d\- ]{9}$/
    /* eslint-enable no-useless-escape */
    const validPhone = regExp.test(localPhone)
    return validPhone
}
export const validatorPhone = phone => {
    /* eslint-disable no-useless-escape */
    const localPhone = phone.replace(/[\s-()]/g, '')
    const regExp = /^((\+7))[\d\- ]{10}$/
    /* eslint-enable no-useless-escape */
    const validPhone = regExp.test(localPhone)
    return validPhone
}

export const atLeastOneNumberValidator = val => {
    const regExp = /.*[0-9].*/
    return regExp.test(val)
}
export const atLeastOneCapitalValidator = val => {
    const regExp = /[A-Z]/
    return regExp.test(val)
}
export const quantityValidator = val => {
    const regExp = /[0-9a-zA-Z]{6,12}/
    return regExp.test(val)
}
export const atLeastOneSpecialCharacterValidator = val => {
    const regExp = /[@#$!?%^&+=]/
    return regExp.test(val)
}

export const validatorCreditCard = creditnum => {
    const localCreditnum = creditnum.replace(/\D/g, '')

    const mir = /^2[0-9]{15,15}$/
    const masterCard = /^5[0-9]{15,15}$/
    const visa = /^4[0-9]{15,15}$/

    return (masterCard.test(localCreditnum) || visa.test(localCreditnum) || mir.test(localCreditnum))
}

export const validatorUrlValidator = val => {
    if (val === undefined || val === null || val.length === 0) {
        return true
    }
    /* eslint-disable no-useless-escape */
    const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
    /* eslint-enable no-useless-escape */
    return re.test(val)
}

export const validatorRussianWordsAndNumbers = name => {
    /* eslint-disable no-useless-escape */
    const regExp = /^([А-Яа-я\-\Ёё\s\d]{1,50})$/
    /* eslint-disable no-useless-escape */
    const validRussianName = regExp.test(name)
    return validRussianName
}
export const validatorRussianName = name => {
    /* eslint-disable no-useless-escape */
    const regExp = /^([А-Яа-я\-\Ёё]{1,20})$/
    /* eslint-disable no-useless-escape */
    const validRussianName = regExp.test(name)
    return validRussianName
}

export const validatorExpDate = date => {
    const today = new Date()
    const expMonth = date.split('/')[0]
    const expYear = date.split('/')[1]
    const exDate = new Date('20' + expYear + '-' + expMonth + '-01')
    return exDate > today
}
export const validatorMaxCardDate = date => {
    const expYear = date.split('/')[1]
    return expYear <= 41
}
export const validatorInn = inn => {
    const regExp = /(?!(.)\1{6,})(^[0-9]{9}$)/
    const validvalidatorInn = regExp.test(inn)
    return validvalidatorInn
}
