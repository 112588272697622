import axiosIns from '@/libs/axios'

export default class Register {
    static async first(request) {
        return (await axiosIns.post('/registration/first', request)).data
    }

    static async second(request) {
        return (await axiosIns.post('/registration/second', request)).data
    }

    static async third(request) {
        return (await axiosIns.post('/registration/third', request)).data
    }

    static async addPaySystem(request) {
        return (await axiosIns.post('/pay-system/add-pay-system', request)).data
    }

    static async sendFile(req) {
        return (await axiosIns.post('/registration/send-file', req)).data
    }

    static async fill(request) {
        return (await axiosIns.post('/registration/fill', request)).data
    }

    static async getFieldData() {
        return (await axiosIns.post('/registration/get-field-data', {
            access_token: localStorage.getItem('access_token'),
        })).data
    }

    static async getFieldDop() {
        return (await axiosIns.post('/registration/get-field-dop', {
            access_token: localStorage.getItem('access_token'),
        })).data
    }
}
