export default [
    {
        path: '/',
        name: 'landing',
        component: () => import('@views/landing/Landing.vue'),
        meta: {
            pageTitle: 'Онлайн займы на карту, оформление микрозаймов и экспресс-кредитов от Oneclickmoney',
        },
    },
]
