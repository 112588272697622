export function globalTest() {
    return (localStorage.getItem('part') === 'B' && localStorage.getItem('lean_test_id') === 'OCM_AB_TEST_NEW_UX_UI_GLOBAL') ? 'B' : 'A'
}

export function globalTestFolder() {
    const BTest = localStorage.getItem('part') === 'B' && localStorage.getItem('lean_test_id') === 'OCM_AB_TEST_NEW_UX_UI_GLOBAL'

    const folders = {
        ATest: 'ocm',
        BTest: 'new-ocm',
    }

    return (BTest ? folders.BTest : folders.ATest)
}

export function testUnitHypothesis() {
    if (localStorage.getItem('part') === 'A') {
        return null
    }
    const testId = localStorage.getItem('lean_test_id')
    return testId
}
