import axios from 'axios'

const axiosInsDadata = axios.create({
    baseURL: process.env.DADATA_URL,
    withCredentials: false,
    mode: "cors",
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Token " + process.env.DADATA_TOKEN,
    },
})

export default axiosInsDadata
