import {
    getDataFromLs,
} from '@utils'
import { reactive, ref } from 'vue'
import { mockSystemPaymentsForm, mockThreeStepsForm } from './developerModeData'

export const accessToken = ref(localStorage.getItem('access_token'))

export const registrationFormfirst = ref(null)
export const registrationFormsecond = ref(null)
export const registrationFormthird = ref(null)
export const agreementCheckboxes = ref(null)

export const form = {
    first: reactive(
        getDataFromLs('first')
        ?? (localStorage.getItem('developer_mode')
            ? mockThreeStepsForm.first
            : {
                lean_test_id: localStorage.getItem('lean_test_id'),
                email: '',
                password: '',
                utm_source: 'тест',
                utm_medium: 'тест',
                utm_campaign: 'тест',
                mobile_phone: '',
                first_name: '',
                middle_name: '',
                last_name: '',
                birth_date: '',
                gender: '1',
                check: true,
                is_unsubscribed: true,
                sum: parseInt(
                    localStorage.getItem('loanInfo-sum'),
                    10,
                ),
                period: parseInt(
                    localStorage.getItem('loanInfo-time'),
                    10,
                ),
                promo_code: 'test_code',
            }),
    ),
    second: reactive(
        getDataFromLs('second')
        ?? (localStorage.getItem('developer_mode')
            ? mockThreeStepsForm.second
            : {
                regStepsId: 2,
                passport_number: '',
                passport_issuer_code: '',
                passport_issuer_name: '',
                passport_date: '',
                passport_birth_place: '',
                region: '',
                city: '',
                street: '',
                house_number: '',
                house_building: '',
                legal_region: '',
                legal_city: '',
                legal_street: '',
                legal_house_number: '',
                legal_house_building: '',
                legal_kladr_code: '2300000300004210006',
                legal_postal_code: '100000',
                flat: '',
                kladr_code: '2300000300004210006',
                is_address_match: true,
                postal_code: '100000',

                cohabitor_phone: '79380020212',
                inn: '834321281249',
                snils: '',
            }),
    ),
    third: reactive(
        getDataFromLs('third')
        ?? (localStorage.getItem('developer_mode')
            ? mockThreeStepsForm.third
            : {
                is_workless: false,
                work_place_address: '',
                addPaySystem: 'mir',
                work_phone: '',
                pensioner: false,
                family_income: '',
                family_outgo: '0',
                work_place: '',
                agreeTermsCheckBox2: true,
                dop_phone: '',

                check_1: true,
                check_2: true,
                check_3: true,
                check_other_parties_yes: true,
                check_other_parties_no: true,
                check_fin_sovetnik: true,
                check_insurance: true,
                check_pay_innovationsinsurance: true,
                check_pay_moneyinsurance: false,
                check_pay_renovationinsurance: false,
                check_pay_innovationsfin_sovetnik: true,
                check_pay_moneyfin_sovetnik: false,
                check_pay_renovationfin_sovetnik: false,
                check_4: true,
                check_5: true,
                recurrent: false,
            }),
    ),
}
export const password = ref(null)
export const formToSubmit = {
    first: reactive({}),
    second: reactive({}),
    third: reactive({}),
}
export const systemPayments = reactive(
    localStorage.getItem('developer_mode')
        ? mockSystemPaymentsForm
        : {
            data: [
                {
                    id: '1',
                    fieldType: 'addPaySystem',
                    value: 'mastercard|maestro|visa|mir',
                    position: 6,
                    number: '',
                    exp: '',
                },
                {
                    id: '5',
                    fieldType: 'addPaySystem',
                    value: 'yandex',
                    position: 2,
                    number: '',
                    exp: '123',
                },
            ],
        },
)

export const steps = [
    {
        title: 'Основная информация',
        state: true,
        name: 'first',
        imgPath: 1,
        shortName: 'first',
    },
    {
        title: 'Паспортные данные',
        state: false,
        name: 'second',
        imgPath: 2,
        shortName: 'second',
    },
    {
        title: 'Контактная информация',
        state: false,
        name: 'third',
        imgPath: 3,
        shortName: 'third',
    },
    {
        title: 'Способ получения',
        state: false,
        name: 'activate',
        imgPath: 4,
    },
]
