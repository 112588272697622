import Dadata from '@/api/dadata'
import Logs from '@/api/logs'
import {
    validatePassportDate,
} from '@core/utils/validations/utils'
import {
    getDataFromLs,
    scrollToYOfElement,
} from '@utils'
import {
    agreementCheckboxes,
    form, registrationFormfirst, registrationFormsecond, registrationFormthird,
} from './forms'

export async function validateForm(step) {
    switch (step) {
    case 'first':
        return await registrationFormfirst.value.validate()
    case 'second':
        return (await registrationFormsecond.value.validate() && await passportDateIsValid() && await checkPassport(form.second.passport_number))
    case 'third':
        return (
            (await registrationFormthird.value.validate())
      && agreementCheckboxes.value.validate()
        )
    default:
    }
}
export const accounExisted = async step => {
    const existingProfiles = getDataFromLs('existingProfiles')
    if (existingProfiles !== null) {
        switch (step) {
        case ('first'):
            if (existingProfiles.emails.includes(form.first.email)) {
                await registrationFormfirst.value.refs.email.applyResult({
                    errors: ['Пользователь с таким email уже зарегистрирован. Войдите в личный кабинет.'],
                    valid: false,
                })
                return false
            } if (existingProfiles.phones.includes(form.first.mobile_phone)) {
                await registrationFormfirst.value.refs.mobile_phone.applyResult({
                    errors: ['Пользователь с таким номером телефона уже зарегистрирован. Войдите в личный кабинет.'],
                    valid: false,
                })
                return false
            }
            return true
        case ('second'):
            if (existingProfiles.passports.includes(form.second.passport_number)) {
                await registrationFormsecond.value.refs.passport_number.applyResult({
                    errors: ['уПользователь с таким паспортом уже зарегистрирован. Войдите в личный кабинет.'],
                    valid: false,
                })
                return false
            }
            return true
        default:
            return true
        }
    } else {
        return true
    }
}
export const passportDateIsValid = async () => {
    const passportDate = validatePassportDate(
        form.second.passport_date,
        form.first.birth_date,
    )
    if (!passportDate.isValid) {
        await registrationFormsecond.value.refs.passport_date.applyResult({
            errors: [passportDate.error],
            valid: false,
        })
        scrollToYOfElement(registrationFormsecond.value.refs.passport_date.id)
        return false
    }
    return true
}

async function checkPassport(value) {
    try {
        const res = await Dadata.clear(value, "passport")
        if (res.data.statusCode === 200) {
            const code = res.data[0].qc
            switch (code) {
            case (0):
                return true
            case (1):
                registrationFormsecond.value.refs.passport_number.applyResult({
                    errors: ['Неправильный формат серии или номера'],
                    valid: false,
                })
                await Logs.send({
                    access_token: localStorage.getItem('access_token'), name: 'second', log: 'reg_error: passport is invalid',
                })
                scrollToYOfElement(registrationFormsecond.value.refs.passport_number.id)
                return false
            case (2):
                registrationFormsecond.value.refs.passport_number.applyResult({
                    errors: ['Исходное значение пустое'],
                    valid: false,
                })
                await Logs.send({
                    access_token: localStorage.getItem('access_token'), name: 'second', log: 'reg_error: passport is invalid',
                })
                scrollToYOfElement(registrationFormsecond.value.refs.passport_number.id)
                return false
            case (10):
                registrationFormsecond.value.refs.passport_number.applyResult({
                    errors: ['Недействительный паспорт'],
                    valid: false,
                })
                await Logs.send({
                    access_token: localStorage.getItem('access_token'), name: 'second', log: 'reg_error: passport is invalid',
                })
                scrollToYOfElement(registrationFormsecond.value.refs.passport_number.id)
                return false
            default: return true
            }
        } else {
            return true
        }
    } catch (e) {
        return true
    }
}
