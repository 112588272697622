// eslint-disable-next-line import/prefer-default-export
export function validatePassportDate(value, birthDate) {
    // проверка паспорта
    const pssprtDate = new Date(
        value.replace(/(\d{2}).(\d{2}).(\d{4})/, '$3-$2-$1'),
    )

    // eslint-disable-next-line no-restricted-globals
    if (!(pssprtDate instanceof Date && !isNaN(pssprtDate))) {
        return {
            isValid: false,
            error: 'Введена некорректная дата',
        }
    }

    const now = new Date()

    const pDate14 = new Date(
        birthDate.replace(
            /(\d{2}).(\d{2}).(\d{4})/,
            '$3-$2-$1',
        ),
    )
    pDate14.setFullYear(pDate14.getFullYear() + 14)

    const pDate20 = new Date(
        birthDate.replace(
            /(\d{2}).(\d{2}).(\d{4})/,
            '$3-$2-$1',
        ),
    )
    pDate20.setFullYear(pDate20.getFullYear() + 20)

    const pDate45 = new Date(
        birthDate.replace(
            /(\d{2}).(\d{2}).(\d{4})/,
            '$3-$2-$1',
        ),
    )
    pDate45.setFullYear(pDate45.getFullYear() + 45)

    const ageDude = parseInt(
        yearsDiff(
            new Date(
                birthDate.replace(
                    /(\d{2}).(\d{2}).(\d{4})/,
                    '$3-$2-$1',
                ),
            ),
        ),
    )

    if (pssprtDate < pDate14) {
        return {
            isValid: false,
            error: 'Паспорт должен быть выдан не ранее, чем в 14 лет',
        }
    }
    if (now < pssprtDate) {
        return {
            isValid: false,
            error: 'Дата не должна быть в будущем',
        }
    }
    if (ageDude >= 20 && ageDude < 45) {
        if (pssprtDate <= pDate20) {
            return {
                isValid: false,
                error: 'Ваш паспорт недействителен',
            }
        }
    } else if (ageDude >= 45) {
        if (pssprtDate <= pDate45) {
            return {
                isValid: false,
                error: 'Ваш паспорт недействителен',
            }
        }
    }
    return {
        isValid: true,
    }
}

const yearsDiff = dt => {
    if (dt > new Date()) {
        return 0
    }

    const crntDate = new Date()

    let yearDiff = parseInt(crntDate.getFullYear() - dt.getFullYear())

    // прошёл уже текущий год или ещё нет
    const dat4check = new Date(dt)
    dat4check.setFullYear(crntDate.getFullYear())
    if (dat4check > crntDate) {
        yearDiff--
    }

    if (yearDiff <= 0) {
        return 0
    }

    if (yearDiff === 1) {
        const monthDiff = parseInt(crntDate.getMonth() - dt.getMonth())
        if (monthDiff >= 0) {
            if (monthDiff === 0) {
                const dayDiff = parseInt(crntDate.getDate() - dt.getDate())
                if (dayDiff > 0) {
                    return yearDiff
                }
                return 0
            }

            return crntDate.getFullYear() - dt.getFullYear()
        }
        return 0
    }
    return yearDiff
}

export function mmddyyyToddmmyyy(date) {
    const parts = date.split(".")
    return new Date(parts[2], parts[1] - 1, parts[0])
}
