<template>
  <div class='c-sidebar__outer'>
    <div
      id='sidebar'
      class=' bg-dark text-dark c-sidebar'
      :class='{ "c-sidebar-open": sideBarToggle }'
    >

      <header-sidebar @sideBarToggle='sideBarToggle = !sideBarToggle' />

      <div class='list-tm-open'>
        <ul>
          <li
            v-for='(sidebarItem, ind) in signIn
              ? sideBarAutorized
              : sideBarUnautorized'
            :key='ind'
          >
            <b-link :href='sidebarItem.link'>
              {{ sidebarItem.title }}
            </b-link>
          </li>
        </ul>
      </div>

      <div v-if='signIn && userInfo !== null'>
        <div class='account-info'>
          <div class='account-info__title'>
            Вы вошли как:
          </div>

          <a
            class='account-info__name'
            href='/application'
          >
            {{ userInfo.fullName }}
          </a>

          <a
            href='/application'
            class='account-info__email'
          >{{ userInfo.email }}</a>

          <a
            class='account-info__birth-date'
            href='/application'
          >{{ userInfo.birthDate }}</a>

        </div>
        <div class='logout-button'>

          <button
            type='button'
            @click='logOut($route.path)'
          >
            Выход
          </button>

        </div>
      </div>

      <div v-else>
        <div class='text-center c-sidebar__phone'>
          <b-link
            class='text-white d-block'
            href='tel:8 (800) 700 06 07'
          >8 (800) 700 06 07</b-link>
          <button
            class='c-sidebar__call'
            @click='{}'
          >
            Заказать обратный звонок
          </button>
        </div>
        <div
          class='sidebar-getloan'
          @click='takeLoan'
        >
          <span>Оформить займ</span>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

import useRegistration from '@/helper/registration/useRegistration'
import HeaderSidebar from '@components/header/HeaderSidebar.vue'
import {
  getCookie,
  getDataFromLs,
  getLastRegIndex, getRegName,
} from '@utils'
import { BLink } from 'bootstrap-vue'
import { BroadcastChannel } from 'broadcast-channel'

export default {
    components: {
        BLink,
        HeaderSidebar,
    },
    setup() {
        const {
            logOut,
        } = useRegistration()
        return {
            logOut,
        }
    },
    data() {
        return {
            sideBarToggle: false,
            userInfo: null,
            signIn: null,
            sideBarAutorized: [
                {
                    title: 'Оформить займ',
                    link: '/application',
                },
                {
                    title: 'История операций',
                    link: '/account/history/',
                },
                {
                    title: 'Автоплатеж',
                    link: '/account/card/',
                },
                {
                    title: 'Личные данные',
                    link: '/account/edit/?page=main',
                },
                {
                    title: 'Выдача займа',
                    link: '/account/edit/?page=output-loan',
                },
            ],
            sideBarUnautorized: [
                {
                    title: 'О компании',
                    link: '/about',
                },
                {
                    title: 'Как это работает',
                    link: '/how-it-works',
                },
                {
                    title: 'Контакты',
                    link: '/contacts',
                },
                {
                    title: 'Вакансии',
                    link: '/vacancies',
                },
            ],
        }
    },
    async beforeCreate() {
        const notificationChannel = new BroadcastChannel('toggleSideBar')
        notificationChannel.onmessage = async () => {
            if (!this.sideBarToggle) {
                this.signIn = getCookie('loanWasTaken') === 'true'
                this.updateUserInfo()
            }
            this.sideBarToggle = !this.sideBarToggle
        }
    },
    methods: {
        updateUserInfo() {
            const first = getDataFromLs('first')
            if (!first) {
                this.signIn = false
                return
            }
            this.userInfo = {
                fullName: this.getFullName(first),
                email: first.email,
                birthDate: first.birth_date,
            }
        },

        takeLoan() {
            const lastRegIndex = getLastRegIndex()
            if (lastRegIndex !== null) {
                this.$router.push({ name: getRegName(lastRegIndex + 1) }).then(() => { this.sideBarToggle = false })
            } else {
                this.$router.push({ name: 'first' }).then(() => { this.sideBarToggle = false })
            }
        },
        getFullName(userInfo) {
            let name = `${userInfo.first_name} ${userInfo.last_name}.`
            if (userInfo.middle_name !== 'Нет') {
                name += ` ${userInfo.middle_name[0]}.`
            }
            return name
        },
    },

}
</script>

<style lang='scss' >
@import "@core/scss/base/bootstrap-extended/_variables.scss";
.c-sidebar {
  background: rgba(8, 46, 71);
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  outline: 0;
  transition: 0.5s;
  left: -100%;
  right: auto;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  &__outer {
    z-index: 1500;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
  }

  &-open {
    transition: 0.5s;
    display: flex;
    left: 0;
  }
  &__call {
    background: 0 0;
    padding: 0;
    border: none;
    display: inline;
    font-size: 24px;
    margin-top: 16px;
    font-family: "ConsolaMonoBold", sans-serif;
    position: relative;
    text-decoration: none;
    color: $on-secondary-surface;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      bottom: -3px;
      background-color: $on-secondary-surface;
    }
  }

  &__phone {
    font-size: 24px;
    font-family: "ConsolaMono", sans-serif;
    margin-top: 30px;

    @media only screen and (max-width: 425px) {
      font-size: 20px;
    }

    > button {
      background: 0 0;
      padding: 0;
      border: none;
      display: inline;
      font-size: 24px;
      font-family: "ConsolaMono", sans-serif;
      position: relative;
      text-decoration: none;
      color: $on-secondary-surface;

      @media only screen and (max-width: 425px) {
        font-size: 16px;
      }

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        bottom: -3px;
        background-color: $on-secondary-surface;
      }
    }
  }

  .sidebar-getloan {
    cursor: pointer;
    width: 100%;
    padding: 5px 48px 20px;
    @media only screen and (max-width: 425px) {
      padding: 0 25px 20px;
    }
    @media only screen and (max-width: 320px) {
      margin-top: 20px;
    }
    > span {
      width: 100%;
      display: block;
      color: $on-primary !important;
      background-color: $primary;
      text-decoration: none;
      padding: 17px 0 20px;
      font-size: 24px;
      font-family: "ConsolaMonoBold", sans-serif;
      text-align: center;

      @media only screen and (max-width: 425px) {
        padding: 13px 0 17px;
        font-size: 18px;
      }
    }
  }
  .list-tm-open {
    width: 100%;
    margin: 30px 0 0;
    @media only screen and (max-width: 425px) {
      margin-top: 25px;
    }

    > ul {
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 0;
      > li {
        width: 100%;
        font-size: 21px;
        line-height: 36px;
        border-bottom: 1px solid $secondary-surface-light;
        &:first-child {
          border-top: 1px solid $secondary-surface-light;
        }
        @media only screen and (max-width: 425px) {
          font-size: 18px;
          line-height: 25px;
        }
        > a {
          width: 100%;
          color: $on-secondary-surface;
          text-decoration: none;
          display: block;
          padding: 20px 48px;
          @media only screen and (max-width: 425px) {
            padding: 20px 25px;
          }
        }
      }
    }
  }
  .account-info {
    width: 100%;
    margin: 0;
    padding: 24px 48px;
    color: $on-secondary-surface;
    font-size: 21px;
    line-height: 36px;
    display: block;
    @media only screen and (max-width: 425px) {
      padding: 20px 25px;
      font-size: 18px;
      line-height: 25px;
    }
    @media only screen and (max-width: 320px) {
      padding: 20px 25px;
      font-size: 16px;
      line-height: 21px;
    }

    &__title {
      margin: 0;
      color: #fff;
      font-family: "ConsolaMonoBold", sans-serif;
      @media only screen and (max-width: 425px) {
        font-size: 18px;
        line-height: 25px;
      }
      @media only screen and (max-width: 320px) {
        font-size: 16px;
        line-height: 21px;
      }
    }
    &__name,
    &__birth-date {
      display: block;
      text-decoration: none;
      color: #fff;
    }
    &__email {
      display: block;
      white-space: nowrap;
      color: $primary;
    }
  }
  .logout-button {
    width: 100%;
    padding: 0 48px;
    bottom: 0;
    left: 0;
    background-color: $secondary-surface;
    text-align: center;
    @media only screen and (max-width: 425px) {
      padding: 0 25px;
    }
    @media only screen and (max-width: 320px) {
      margin-top: 20px;
    }
    button {
      width: auto;
      display: inline-block;
      color: #fdb813;
      border: none;
      background: 0 0;
      text-decoration: none;
      font-size: 24px;
      font-family: "ConsolaMonoBold", sans-serif;
      text-align: center;
      @media only screen and (max-width: 425px) {
        padding: 13px 0 17px;
        font-size: 18px;
      }
    }
  }
}
</style>
