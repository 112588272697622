export default [
    {
        path: '/error-404',
        name: 'error-404',
        meta: {
            hide: true,
            sitemap: {
                ignoreRoute: true,
            },
            pageTitle: 'OneClickMoney',
        },
    },
]
