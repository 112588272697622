<template>
  <div class='app-skelet'>
    <div class='content-wrap'>
      <loan-processing-skeleton />
    </div>
  </div>
</template>

<script>
import LoanProcessingSkeleton from './skeleton/LoanProcessingSkeleton.vue'

export default {
    components: {
        LoanProcessingSkeleton,
    },

}
</script>

<style lang="scss" scoped>
.app-skelet {
    // max-width: 1800px;
    // margin: 0 auto;
}
</style>
