export const saveDataToLs = (obj, name) => {
    const savedObject = JSON.stringify(obj)
    localStorage.setItem(name, savedObject)
}

export const getDataFromLs = name => {
    try {
        return JSON.parse(localStorage.getItem(name))
    } catch (e) {
        return null
    }
}
export function getCookie(name) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    const result = parts.pop().split(';').shift()

    return result
}
