<template>
  <div class='c-modal'>
    <b-modal
      id='custom_modal'
      v-model='modalToggle'
      no-fade

      centered
      scrollable
      modal-class='modal'
      @show='open'
      @hidden='close'
      @ok='close'
    >

      <template #modal-header='{ close }'>
        <div class='modal-close__wrap'>
          <div
            class='modal-close'
            @click='close()'
            @keyDown='close()'
          >
            <div class='line-p line-p1' />
            <div class='line-p line-p2' />
          </div>
        </div>
      </template>

      <template #modal-footer='{ ok, cancel, hide }'>
        <div />
      </template>
      <!-- <template #modal-header-close'>
        <div class='modal-close__wrap'>
          <div
            class='modal-close'
            @click='close'
            @keyDown='close'
          >
            <div class='line-p line-p1' />
            <div class='line-p line-p2' />
          </div>
        </div>
      </template> -->
      <div class='c-modal__content'>
        <slot name='content' />
      </div>

    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue'

export default {
    components: {
        BModal,
    },
    props: {
        toggle: {
            type: Boolean,
            require: false,
        },
    },

    computed: {
        modalToggle: {
            get() {
                return this.toggle
            },
            set() {
                return this.toggle
            },
        },
    },

    methods: {
        close() {
            this.$emit('closeModal')
        },
        open() {
            this.$emit('openModal')
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/base/bootstrap-extended/_variables.scss";
#custom_modal___BV_modal_outer_ {
  z-index: 2000 !important;
}
.c-modal {
  &__content {
    padding: 0 20px;
  }
.modal-content {
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important;
  border: none !important;
  @media only screen and (max-width: 770px) {
    height: 100vh;
  }
}
.modal-header {
  border-bottom: 0 !important;
}
.modal-footer {
  border-top: 0 !important;
  height: 20px;
}
.modal-dialog {
  max-width: 900px !important;
  @media only screen and (max-width: 940px) {
    margin: auto 20px !important;
  }
  @media only screen and (max-width: 770px) {
    width: 100vw;
    margin: 0 !important;
    height: 100vh;
    max-width: 100vw !important;
    max-height: 100vh !important;
  }
  @media only screen and (min-width: 771px) {
    margin: 1.75rem auto;
  }
}

.modal-content {
  padding: 0 20px 50px 20px;
  width: 600px !important;
  max-height: 95vh !important;
  overflow: scroll !important;
  border-radius: 0 !important;
  @media only screen and (max-width: 770px) {

    width: 100vw !important;
    height: 100vh;
    max-width: 100vw !important;
    max-height: 100vh !important;
  }
  @media only screen and (min-width: 1366px) {
    width: 900px !important;
  }
}



  .fade {
    background-color: rgba(0, 0, 0, 0.6) !important;
  }
  .modal {
    border-radius: 0 !important;
    @media (max-width: 576px) {
      padding-right: 0;
      padding-left: 0;
    }
  }

.modal-body {
  padding: 0 !important;
  max-height: 90vh !important;
}
.modal-body img {
  max-height: 69vh !important;
}
.modal-close {
  right: 20px;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 20px;
  cursor: pointer;
  display: block;
  z-index: 1000;

  @media only screen and (max-width: 1200px) {
    width: 18px;
    height: 18px;

    top: 20px;
  }
  &__wrap {
    height: 50px;
  }
}
.line-p {
  width: 100%;
  height: 3px;
  top: 10px;
  position: absolute;
  background-color: $on-background;
  transition: all 0.4s ease-out;
  @media only screen and (max-width: 1200px) {
    top: 8px;
  }
}
.line-p1 {
  left: 0;
  transform: rotate(45deg);
}
.line-p2 {
  right: 0;
  transform: rotate(-45deg);
}
}
</style>
