import { globalTestFolder } from '@utils'

export default [
    {
        path: '/registration/first',
        name: 'first',
        meta: {
            pageTitle: 'Регистрация: 1 шаг для получения онлайн-займа в ONECLICKMONEY.RU',
            regIndex: 0,
        },
        component: () => import(`@/views/${globalTestFolder()}/registration/Registration.vue`),
    },
    {
        path: '/registration/second',
        name: 'second',
        meta: {
            pageTitle: 'Регистрация: 2 шаг',
            regIndex: 1,
        },
        component: () => import(`@/views/${globalTestFolder()}/registration/Registration.vue`),
    },
    {
        path: '/registration/third',
        name: 'third',
        meta: {
            pageTitle: 'Авторизация карты',
            regIndex: 2,
        },
        component: () => import(`@/views/${globalTestFolder()}/registration/Registration.vue`),
    },
    {
        path: '/registration/activate',
        name: 'activate',
        meta: {
            pageTitle: 'Активация аккаунта',
            regIndex: 3,
        },
        component: () => import(`@views/ocm/registration/activate/ActivateWrapper.vue`),
    },
    {
        path: '/fill',
        name: 'fill',
        meta: {
            pageTitle: 'Предоставьте фотографии для подтверждения, что Вы являетесь владельцем указанного паспорта',
            regIndex: 4,
        },
        component: () => import(`@/views/${globalTestFolder()}/registration/fill/FillWrapper.vue`),
    },
]
