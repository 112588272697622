<template>
  <div>
    <div
      class='new-sidebar-header'
    >
      <div class='d-flex justify-content-between'>
        <div
          v-if='!signIn'
          class='new-sidebar-header__head'
          aria-label='open login form'
          @click='loginModalToggle'
          @keypress='loginModalToggle'
        />

        <a
          href='/'
          class='new-sidebar-header__logo'
        >
          <span>One Click Money</span>
        </a>

        <button
          type='button'
          class='close-menu'
          aria-label='close menu'
          @click='$emit("sideBarToggle")'
        >
          <span class='line-c line-c1' /><span class='line-c line-c2' />
        </button>

        <!-- <b-link
          class='new-header-md__head'
          aria-label='go to registration route'
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getCookie, redirectToMain } from '@utils'
import { VBToggle } from 'bootstrap-vue'
import Vue from 'vue'

Vue.directive('b-toggle', VBToggle)
export default {
    props: {
        color: {
            type: String,
            require: false,
            default: '',
        },
    },
    data() {
        return {
            signIn: getCookie('loanWasTaken') === 'true',
        }
    },
    methods: {
        logOut() {
            redirectToMain({ log: 'redirect_already_registred', path: this.$route.path })
        },
        loginModalToggle(value) {
            if (value) {
                this.$router.push({ hash: '#signin' })
            } else {
                this.$router.push({ hash: '' })
            }
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/base/bootstrap-extended/_variables.scss";

.header-sm__logo {
  margin-top: 8px;
  margin-left: 13px;
}

.new-sidebar-header {
  background-color: $new-tertiary-surface;
  padding: 10px 48px;
  height: 60px;
  margin-left: 0 !important;
  width: 100%;

  @media only screen and (max-width: 1200px) {
    padding-right: calc(100% * 0.05);
    padding-left: calc(100% * 0.05);
  }
  @media screen and (min-width: 961px) {
    display: none;
  }
  &__logo {
    display: block;
    background: url(@/assets/images/ocm/logo/short-yb.svg) no-repeat center;
    > span {
      opacity: 0;
    }

    background-size: contain;
    width: 93px;
    height: 30px;
    align-self: flex-end;
  }
  &__head {
    background: url(@/assets/images/ocm/components/header/regestration-head.svg)
      no-repeat center;
    background-size: contain;
    width: 30px;
    height: 30px;
    position: relative;
    padding: 0;
    margin-top: 7px;
  }
  .close-menu {
    width: 25px;
    height: 25px;
    position: relative;
    background: 0 0;
    margin-top: 5px;

    .line-c {
      width: 100%;
      height: 3px;
      background-color: $new-on-tertiary-surface;
      position: absolute;
      top: 12px;
    }

    .line-c1 {
      transform: rotate(45deg);
      left: 2px;
    }

    .line-c2 {
      transform: rotate(-45deg);
      right: -2px;
    }
  }
  &__logo-wrap {
    margin: auto 0;
  }

  &__logo {
    height: 30px;
  }
}
</style>
