/* eslint-disable import/prefer-default-export */
import Toastify from 'toastify-js'
import { globalTest } from './hypotheses'

export const showToast = options => {
    const test = globalTest()
    switch (test) {
    case ("B"):
        Toastify({
            text: options.text,
            className: `new-toastify ${options.status === "error" ? "new-toastify-error" : ""}`,
            duration: 4000,
            close: true,
            gravity: "bottom",
            position: "center",
            stopOnFocus: true,
        }).showToast()
        break
    default:
        Toastify({
            node: getToastifyAHTML(options),
            text: options.text,
            className: `c-toastify  ${options.status === "error" ? "c-toastify_error" : "c-toastify_success"}`,
            duration: 4000,
            close: true,
            gravity: "top",
            position: "center",
            stopOnFocus: true,
        }).showToast()
        const regAlert = document.querySelector('.toastify')
        setTimeout(() => {
            regAlert.classList.add('c-toastify__back-remover')
            if (options.status === "error") {
                regAlert.classList.add('c-toastify__back-remover_error')
            } else {
                regAlert.classList.add('c-toastify__back-remover_success')
            }
        }, 3)
        break
    }
}

function getToastifyAHTML(options) {
    const HTML = document.createElement('div')
    HTML.innerHTML = `
          <div class='c-toastify__wrap'>
          <svg
        width='30'
        height='30'
        viewBox='0 0 30 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
>
        <path
                d='M26.2729 8.63215L17.4604 0.307148C17.2511 0.109863 16.9751 0 16.6875 0H4.5C3.87861 0 3.375 0.503613 3.375 1.125V28.875C3.375 29.4964 3.87861 30 4.5 30H25.5C26.1214 30 26.625 29.4964 26.625 28.875V9.45C26.625 9.14062 26.4975 8.84473 26.2729 8.63215ZM24.375 27.75H5.625V2.25H16.2401L24.375 9.93486V27.75Z'
                fill='white'
        />
        <path
                d='M20.25 18.375H9.375C8.75361 18.375 8.25 18.8786 8.25 19.5C8.25 20.1214 8.75361 20.625 9.375 20.625H20.25C20.8714 20.625 21.375 20.1214 21.375 19.5C21.375 18.8786 20.8714 18.375 20.25 18.375Z'
                fill='white'
        />
        <path
                d='M15.75 13.875H9.375C8.75361 13.875 8.25 14.3786 8.25 15C8.25 15.6214 8.75361 16.125 9.375 16.125H15.75C16.3714 16.125 16.875 15.6214 16.875 15C16.875 14.3786 16.3714 13.875 15.75 13.875Z'
                fill='white'
        />
        <path
                d='M20.25 22.875H9.375C8.75361 22.875 8.25 23.3786 8.25 24C8.25 24.6214 8.75361 25.125 9.375 25.125H20.25C20.8714 25.125 21.375 24.6214 21.375 24C21.375 23.3786 20.8714 22.875 20.25 22.875Z'
                fill='white'
        />
        <path
                d='M17.625 8.625V1.125H15.375V9.75C15.375 10.3714 15.8786 10.875 16.5 10.875H25.5V8.625H17.625Z'
                fill='white'
        />
</svg>
            <p class='c-toastify__text'>
            ${options.text}
            </p>
          </div>
          <div
            class='c-toastify__remove'
          />`
    return HTML
}
