import axiosInsDadata from './axiosDadata'

export default class DadataLibs {
    static async suggestions(query, field, locations = [], from_bound = {}, to_bound = {}, count = 5) {
        return await axiosInsDadata.post('/suggest/' + field, JSON.stringify({
            query, locations, from_bound, to_bound, restrict_value: true, count,
        }))
    }

    static async fingById(inn) {
        return await axiosInsDadata.post('/findById/party', JSON.stringify({
            query: inn,
        }))
    }
}
