import axiosIns from '@/libs/axios'

export default {
    namespaced: true,
    state: {
        user: 0,
    },
    getters: {
        user(state) {
            return state.user
        },

        cache() {
            return JSON.parse(localStorage.getItem('userData'))
        },

        ethWallet() {
            const user = JSON.parse(localStorage.getItem('userData'))
            if (user.wallets.length > 0) {
                return user.wallets.filter(wallet => wallet.currency === 'ETH')[0].address
            }
            return ''
        },
    },
    mutations: {
        updateUser(state, user) {
            state.user = user
        },
    },
    actions: {
        async show(ctx) {
            const user = (await axiosIns.get(`/user/show`)).data
            ctx.commit('updateUser', user)
        },

        cacheUser(ctx, user) {
            const {
                general, kyc, notification, wallets, ability, extras, auth_methods, two_fa,
            } = user.data

            const userData = {
                id: general.id,
                avatar: general.avatar ?? '',
                name: general.name ?? '',
                username: general.username ?? '',
                email: general.email,
                role: general.role,
                status: kyc.status,

                general,
                kyc,
                notification,
                wallets,

                ability,
                extras,
                authMethods: auth_methods,
                two_fa,
            }

            localStorage.setItem('userData', JSON.stringify(userData))
            ctx.commit('updateUser', userData)
        },
    },
}
