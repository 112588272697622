import axiosIns from '@/libs/axios'

export default class Lean {
    static async init(req) {
        return (await axiosIns.post('/lean', req)).data
    }

    static async show(leanId) {
        return (await axiosIns.get('/lean/' + leanId)).data
    }
}
