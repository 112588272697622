import { extend, localize, setInteractionMode } from 'vee-validate'
import en from 'vee-validate/dist/locale/en.json'
import ru from 'vee-validate/dist/locale/ru.json'
import {
    alpha as rule_alpha,
    alpha_dash as rule_alpha_dash,
    alpha_num as rule_alpha_num,
    between as rule_between,
    confirmed as rule_confirmed,
    digits as rule_digits,
    integer as rule_integer,
    is as rule_is,
    length as rule_length,
    max as rule_max,
    max_value as rule_max_value,
    min as rule_min,
    min_value as rule_min_value,
    regex as rule_regex,
    required as rule_required,
} from 'vee-validate/dist/rules'

// eslint-disable-next-line object-curly-newline
import { mmddyyyToddmmyyy } from '@core/utils/validations/utils.js'
import {
    atLeastOneCapitalValidator,
    atLeastOneNumberValidator, atLeastOneSpecialCharacterValidator,
    quantityValidator,
    validatoMobilePhone,
    validatorExpDate,
    validatorInn,
    validatorMaxCardDate,
    validatorPhone, validatorPositive,
    validatorRussianName,
    validatorRussianWordsAndNumbers,
    validatorUrlValidator,
} from './validators'
// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', rule_required)

export const min = extend('min', rule_min)
export const max = extend('max', rule_max)

export const minValue = extend('min_value', rule_min_value)

export const maxValue = extend('max_value', rule_max_value)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

export const is = extend('is', rule_is)

export const positive = extend('positive', {
    validate: validatorPositive,
    message: 'Введите положительное число',
})

// export const creditCard = extend('creditCard', {
//     validate: validatorCreditCard,
//     message: 'Укажите корректный номер карты',
// })

export const UMoney = extend('UMoney', {
    validate: value => (value.replace(/\D/g, '').length >= 10),
    message: 'Значение «ЮMoney | Яндекс.Деньги» должно содержать минимум 10 символов.',
})

export const mobilePhone = extend('mobilePhone', {
    validate: validatoMobilePhone,
    message: 'Используйте формат: +7 (9XX) XXX-XX-XX',
})

export const phone = extend('phone', {
    validate: validatorPhone,
    message: 'Используйте формат: +7 (XXX) XXX-XX-XX',
})

export const atLeastOneNumber = extend('atLeastOneNumber', {
    validate: atLeastOneNumberValidator,
    message: 'Пароль должен содержать минимум одну цифру.',
})
export const atLeastOneCapital = extend('atLeastOneCapital', {
    validate: atLeastOneCapitalValidator,
    message: 'Пароль должен содержать минимум одну заглавную букву',
})
export const quantityPassword = extend('quantityPassword', {
    validate: quantityValidator,
    message: 'Пароль должен содержать не менее 6 символов',
})

export const atLeastOneSpecialCharacter = extend('atLeastOneSpecialCharacter', {
    validate: atLeastOneSpecialCharacterValidator,
    message: 'Пароль должен содержать минимум один спецсимвол',
})

export const url = extend('url', {
    validate: validatorUrlValidator,
    message: 'URL is invalid',
})

export const russianName = extend('russianName', {
    validate: validatorRussianName,
    message: 'Используйте только русские буквы и тире',
})
export const russianWords = extend('russianWords', {
    validate: validatorRussianWordsAndNumbers,
    message: 'Используйте только русские буквы и цифры',
})
export const dateBeforeNow = extend('dateBeforeNow', {
    validate: value => mmddyyyToddmmyyy(value) < new Date(new Date().toDateString()),
    message: 'Неправильный формат даты',
})

export const dateFormat = extend('dateFormat', {
    validate: value => {
        const regExp = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/
        return regExp.test(value)
    },
    message: 'Неправильный формат даты',
})

export const bDBefore1930 = extend('bDBefore1930', {
    validate: date => {
        const birthDate = mmddyyyToddmmyyy(date)
        return birthDate.getFullYear() >= 1930
    },
    message: 'Неправильный формат даты',
})

export const tooOld = extend('tooOld', {
    validate: date => {
        const birthDate = mmddyyyToddmmyyy(date)
        const today = new Date()

        let result = today.getFullYear() - birthDate.getFullYear()
        const m = today.getMonth() - birthDate.getMonth()
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            result--
        }
        return !!(result <= 80)
    },
    message: 'Вам исполнилось 80 лет',
})

export const tooYoung = extend('tooYoung', {
    validate: date => {
        const birthDate = mmddyyyToddmmyyy(date)
        const today = new Date()
        let result = today.getFullYear() - birthDate.getFullYear()
        const m = today.getMonth() - birthDate.getMonth()
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            result--
        }
        return !!(result >= 18)
    },
    message: 'Вам не исполнилось 18 лет',
})

export const expDate = extend('expDate', {
    validate: validatorExpDate,
    message: 'Истек срок действия карты',
})

export const maxCardDate = extend('maxCardDate', {
    validate: validatorMaxCardDate,
    message: 'Неверный срок действия карты',
})

export const inn = extend('inn', {
    validate: validatorInn,
    message: 'Неправильный инн',
})

export const requiredCheckBox = extend('requiredCheckBox', {
    validate: value => (value === true),
    message: 'Необходимо поставить галочку',
})

export const email = extend('email', {
    validate: value => {
        const regExp = /^[-\w.]+@([A-z0-9]+\.)+[A-z]{1,10}$/
        return regExp.test(value)
    },
    message: 'Значение «E-mail» не является правильным email адресом.',
})
export const russian = extend('russian', {
    validate: () => true,
    message: '',
})

export const date = extend('date', {
    validate: () => true,
    message: '',
})

export const russianAndNumbers = extend('russianAndNumbers', {
    validate: () => true,
    message: '',
})

export const smsCode = extend('smsCode', {
    validate: () => true,
    message: '',
})
export const creditCard = extend('creditCard', {
    validate: () => true,
    message: '',
})
export const confirm = extend('confirm', {
    validate: () => true,
    message: '',
})
export const passport = extend('passport', {
    validate: value => {
        const pass = value.replace(/\D/g, '')
        return pass.length === 10
    },
    message: "Значение «Серия и номер паспорта» неверно.",
})

export const issuerCode = extend('issuerCode', {
    validate: () => true,
    message: '',
})
export const pasportDate = extend('pasportDate', {
    validate: () => true,
    message: '',
})

export const password = extend('password', {
    validate: () => true,
    message: '',
})
export const passwordConfirm = extend('passwordConfirm', {
    validate: () => true,
    message: '',
})

setInteractionMode('betterLazy', () => ({
    on: ['change', 'blur'],
    debounce: 200,
}))

// Install English and Arabic localizations.
localize({
    ru: {
        messages: Object.assign(ru.messages),
        names: {
            email: 'E-mail',
            password: 'Пароль',
            password_confirm: '«Еще раз пароль»',
            birth_date: '«Дата рождения»',
            activate_code: '«СМС-код»',
            mobile_phone: '«Мобильный телефон»',
            name: '«Имя»',
            middlename: '«Отчество»',
            surname: '«Фамилия»',
            passport_number: '«Серия и номер паспорта»',
            passport_issuer_code: '«Код подразделения»',
            passport_issuer_name: '«Кем выдан (как в паспорте)»',
            passport_date: '«Когда выдан»',
            passport_birth_place: '«Место рождения (как в паспорте)»',
            region: '«Регион»',
            city: '«Город»',
            street: '««Улица»»',
            house_number: '«Дом»',
            legal_region: '«Регион»',
            legal_city: '«Город»',
            legal_street: '«Улица»',
            legal_house_number: '«Дом»',
            flat: '«Квартира»',
            work_place_address: '«Адрес места работы»',
            work_phone: '«Рабочий телефон»',
            family_income: '«Среднемесячный доход (руб.)»',
            family_outgo: '«Ежемесячные расходы по кредитам и займам (руб.)»',
            work_place: '«Место работы»',
            card_number: 'Номер карты',
            card_exp: '«Срок действия карты»',
            yandex_number: '«ЮMoney | Яндекс.Деньги»',
            credit_sum: '«Сумма кредита»',
            credit_period: '«Срок»',
            credit_org: '«Организация, перед которой у Вас есть долговые обязательства»',
            dop_phone: '«Доп. контактный телефон»',
            contactform_number: '«Номер договора»',
            contscts_text: '«Текст сообщения»',
            contactform_email: 'E-mail',
            contactform_name: '«Ваше имя»',
            passwordConfirm: '«Повторите пароль»',
        },
        fields: {
            family_income: {
                max_value: 'Значение {_field_} не должно превышать 1000000',
            },
            family_outgo: {
                max_value: 'Значение {_field_} не должно превышать 1000000',
            },
            activate_code: {
                length: 'Значение {_field_} должно содержать минимум 6 символов.',
            },
            street: {
                required: 'Улица - обязательное поле (если отсутствует, укажите "нет").',
            },
            legal_street: {
                required: 'Улица - обязательное поле (если отсутствует, укажите "нет").',
            },
            middlename: {
                required: '* Если нет отчества, укажите "Нет"',
            },
            passwordConfirm: {
                is: 'Пароли не совпадают',
            },
            email: {
                required: 'Необходимо заполнить {_field_}.',
            },
            phone: {
                required: 'Необходимо заполнить {_field_}.',
            },

        },
    },
    en: {
        messages: en.messages,
        names: {
            email: 'Email',
            password: 'Password',
        },
        fields: {
            password: {
                min: '{_field_} is too short, you want to get hacked?',
            },
        },
    },
})
localize('ru')
// ////////////////////////////////////////////////////////
// NOTE:
// Quasar validation for reference only
// Remove this note once development is finished and make sure to
// to convert all of them in veevalidate version
// ////////////////////////////////////////////////////////

// export const required = (val) => {
//   return (val && val.length > 0) || '*Field is required'
// }

// export const required_obj = (obj) => {
//   if (obj === null || obj === undefined) return '*Field is required'
//   return (Object.entries(obj).length > 0 && obj.constructor === Object) || '*Field is required'
// }

// export const no_blank_spaces_arr = (arr) => {
//   return arr.every(val => (val.trim() && val.trim().length > 0)) || 'Blank Spaces are not allowed'
// }

// export const url = val => {
//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // Used
//   // https://stackoverflow.com/questions/4314741/url-regex-validation

//   // Other
//   // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
//   // https://www.w3resource.com/javascript-exercises/javascript-regexp-exercise-9.php
//   // https://www.geeksforgeeks.org/how-to-validate-url-using-regular-expression-in-javascript/

//   /* eslint-disable no-useless-escape */
//   const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
//   /* eslint-enable no-useless-escape */
//   return re.test(val) || 'URL is invalid'
// }

// export const date = val => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // https://github.com/quasarframework/quasar/blob/dev/ui/src/utils/patterns.js
//   return /^-?[\d]+\/[0-1]\d\/[0-3]\d$/.test(val) || 'Date is invalid'
// }

// export const max = (val, max) => {

//   // If blank return
//   if (val === undefined || val === null) return true

//   return val.length <= max || `More than ${max} characters are not allowed`
// }

// export const max_arr = (val, max) => {
//   return val.length <= max || `More than ${max} values are not allowed`
// }

// export const min = (val, min) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   return val.length >= min || `Minimum ${min} characters are required`
// }

// export const num_range = (val, min, max) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   const msg = 'Value is invalid'
//   if (min === null) return val <= max || msg
//   else if (max === null) return val >= min || msg
//   else return (val >= min && val <= max) || msg
// }
