import { globalTestFolder } from '@utils'

export default [
    {
        path: '/application',
        name: 'application',
        meta: {
            pageTitle: 'OneClickMoney',
            regIndex: 5,
        },
        component: () => import(`@/views/${globalTestFolder()}/lk/ApplicationWrapper.vue`),
    },
]
