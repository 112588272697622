<template>
  <div class='loan-processing'>
    <vue-skeleton-loader
      width='100%'
      height='100%'
      animation='fade'
    />
  </div>
</template>

<script>

export default {
}
</script>
<style scoped lang='scss'>
.loan-processing {
  height: 100vh;
  // @media screen and (min-width: 650px) {
  //   height: 590px;
  // }

}
</style>
