import VueGtag from '@gtm-support/vue2-gtm'
import * as Sentry from "@sentry/vue"
import PortalVue from 'portal-vue'
import VueSkeletonLoader from 'skeleton-loader-vue'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/libs/acl'
import '@/libs/clipboard'
import '@/libs/portal-vue'
import '@axios'
import "range-slider-element/dist/styles.css"
import "toastify-js/src/toastify.css"

Vue.component('VueSkeletonLoader', VueSkeletonLoader)
Vue.use(PortalVue)
require('@core/scss/core.scss')

Vue.config.productionTip = false

// Подключаем googleAnalytics и yandexMetrica

if (process.env.DEV === 'false') {
    if (process.env.SEND_ANALYTICS !== 'false') {
        Vue.use(VueGtag, {
            id: process.env.VUE_APP_GTM_ID,
            defer: true,
            enabled: true,
            loadScript: true,
            vueRouter: router,
            debug: false,
            trackOnNextTick: false,
        })
    }

    Sentry.init({
        Vue,
        dsn: process.env.SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],
        trackComponents: true,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    })
}

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
