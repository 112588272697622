import RandExp from 'randexp'

export const mockThreeStepsForm = {
    first: {
        lean_test_id: localStorage.getItem('lean_test_id'),
        email: new RandExp(/[0-9]{6}$/gm).gen() + '@115.ru',
        password: '121212',
        utm_source: 'тест',
        utm_medium: 'тест',
        utm_campaign: 'тест',
        mobile_phone:
        '+7 (912) '
        + new RandExp(/[0-9]{3}$/gm).gen()
        + '-'
        + new RandExp(/[0-9]{2}$/gm).gen()
        + '-'
        + new RandExp(/[0-9]{2}$/gm).gen(),
        first_name: 'тест',
        middle_name: 'тест',
        last_name: 'тест',
        birth_date: '01.10.2000',
        gender: '2',
        check: true,
        is_unsubscribed: true,
        sum: 20000,
        period: 60,
        promo_code: 'test_code',
    },
    second: {
        regStepsId: 2,
        passport_number: '1058-' + new RandExp(/[0-9]{6}$/gm).gen(),
        passport_issuer_code: '220-051',
        passport_issuer_name: ' ГУ МВД РОССИИ ПО КРАСНОДАРСКОМУ КРАЮ',
        passport_date: '01.10.2022',
        passport_birth_place: 'г Геленджик',
        region: 'Краснодарский край',
        city: 'г Геленджик',
        street: 'ул Тополиная',
        house_number: '67',
        house_building: '',
        flat: '4',
        kladr_code: '2300000300004210006',
        is_address_match: true,
        postal_code: '100000',
        cohabitor_phone: '79380020212',
        inn: '834321281249',
        snils: '',
        legal_region: '',
        legal_city: '',
        legal_street: '',
        legal_house_number: '',
        legal_house_building: '',
        legal_kladr_code: '2300000300004210006',
    },
    third: {
        is_workless: true,
        work_place_address: '',
        addPaySystem: 'mir',
        work_phone: '',
        pensioner: false,
        family_income: '5',
        family_outgo: '0',
        work_place: '',
        agreeTermsCheckBox2: true,
        dop_phone: '+7 (912) '
        + new RandExp(/[0-9]{3}$/gm).gen()
        + '-'
        + new RandExp(/[0-9]{2}$/gm).gen()
        + '-'
        + new RandExp(/[0-9]{2}$/gm).gen(),

        check_1: true,
        check_2: true,
        check_3: true,
        check_other_parties_yes: true,
        check_other_parties_no: true,
        check_fin_sovetnik: true,
        check_insurance: true,
        check_pay_innovationsinsurance: true,
        check_pay_moneyinsurance: false,
        check_pay_renovationinsurance: false,
        check_pay_innovationsfin_sovetnik: true,
        check_pay_moneyfin_sovetnik: false,
        check_pay_renovationfin_sovetnik: false,
        check_4: true,
        check_5: true,
        recurrent: false,
    },
}

export const mockSystemPaymentsForm = {
    data: [
        {
            id: '1',
            fieldType: 'addPaySystem',
            value: 'mastercard|visa|mir',
            position: 6,
            number: '4314090010071979',
            exp: '01/25',
        },
        {
            id: '5',
            fieldType: 'addPaySystem',
            value: 'yandex',
            position: 2,
            number: '41001 75017 397',
            exp: '123',
        },
    ],
}
