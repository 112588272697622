import { globalTestFolder } from '@utils'

export default [
    {
        path: '/about',
        name: 'about',
        component: () => import(`@/views/${globalTestFolder()}/others/AboutUs.vue`),
        meta: {
            hide: false,
            pageTitle: 'Информация о компании OneClickMoney',
        },
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => import(`@/views/${globalTestFolder()}/others/AboutUs.vue`),
        meta: {
            pageTitle: 'Общие вопросы',
        },
    },
    {
        path: '/mobile',
        name: 'mobile',
        component: () => import('@views/ocm/others/Mobile.vue'),
        meta: {
            pageTitle: 'Мобильное приложение OneClickMoney',
        },
    },
    // {
    //     path: '/documents',
    //     name: 'documents',
    //     component: () => import('@views/ocm/others/Documents.vue'),
    //     meta: {
    //         hide: true,
    //         pageTitle: 'Правила и документы',
    //     },
    // },
    {
        path: '/pages/privacy-policy',
        name: 'privacy-policy',
        component: () => import('@views/ocm/others/Policy.vue'),
        meta: {
            hide: true,
        },
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: () => import(`@/views/${globalTestFolder()}/others/Contacts.vue`),
        meta: {
            pageTitle: 'Контакты',
        },
    },
    {
        path: '/how-it-works',
        name: 'how-it-works',
        component: () => import(`@/views/${globalTestFolder()}/others/how-it-works/HowItWorks.vue`),
        meta: {
            pageTitle: 'Как получить займ?',
        },
    },
]
