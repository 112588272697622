<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <b-form-group :id="id + '-group'" class="c-input-wrap c-input-group">
    <div class="c-input" placeholder="" style="">
      <div
        class="c-input__label"
        :class="{ 'c-input__label-active': inputActive || localValue != '' }"
      >
        {{ placeholder }}
      </div>
      <validation-provider
        v-slot="{ errors }"
        :rules="
          disabled ? '' : type + dopRules + (isRequired ? '|required' : '')
        "
        :mode="mode"
        :name="id"
      >
        <input
          :id="id"
          v-model="localValue"
          :disabled="disabled"
          :type="
            type.includes('password')
              ? passwordToggle
                ? 'text'
                : 'password'
              : inputType
          "
          :inputmode="inputMode"
          :required="isRequired"
          class="c-input__input"
          :class="{
            'c-input__input-failing': errors[0],
            'c-input__input-outline': focused & outline,
          }"
          @input="onChangeValue"
          @focus="
            () => {
              inputActive = true;
              setMask(localValue);
              focused = true;
            }
          "
          @blur="focused = false"
        />
        <button
          v-if="type.includes('password')"
          type="button"
          class="password-toggle"
          :class="{ 'password-toggle-active': passwordToggle }"
          @click="passwordToggle = !passwordToggle"
        />
        <div v-if="errors[0]" class="c-input__error">
          {{ errors[0] }}
        </div>
      </validation-provider>
      <div v-if="suggestionText" class="c-input__suggestion-text">
        {{ suggestionText }}
      </div>
    </div>

    <div v-if="suggestions.length > 0" id="suggestions" class="suggestions">
      <div
        v-for="(suggestion, ind) in suggestions"
        :key="ind"
        @click="chooseSuggestValue(suggestion.value)"
      >
        <span
          v-for="(item, strInd) in getColorOfSuggestion(suggestion.value)"
          :key="strInd"
        >
          <span :class="{ 'text-danger': item.colored }">{{ item.value }}</span>
        </span>
      </div>
    </div>
  </b-form-group>
</template>

<script>
import DadataLibs from '@/libs/dadata';

import '@validations';
import { BFormGroup } from 'bootstrap-vue';
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    BFormGroup,
    ValidationProvider,
  },
  props: {
    id: {
      type: String,
      require: true,
      default: '',
    },
    value: {
      type: String,
      require: false,
      default: '',
    },
    placeholder: {
      type: String,
      require: true,
      default: '',
    },
    state: {
      type: Boolean,
      require: true,
      default: true,
    },
    type: {
      type: String,
      require: false,
      default: '',
    },
    dateLabel: {
      type: String,
      require: false,
      default: '',
    },

    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    isRequired: {
      type: Boolean,
      require: false,
      default: true,
    },
    dopRules: {
      type: String,
      required: false,
      default: '',
    },
    suggest: {
      type: Object,
      required: false,
      default: () => {},
    },
    outline: {
      type: Boolean,
      required: false,
      default: false,
    },
    mode: {
      type: String,
      required: false,
      default: 'betterLazy',
    },
    suggestionText: {
      type: String || null,
      required: false,
      default: null,
    },
    inputType: {
      type: String,
      required: false,
      default: 'text',
    },
    inputMode: {
      type: String,
      required: false,
      default: 'text',
    },
  },
  data() {
    return {
      localValue: this.value.toString(),
      passwordToggle: false,
      suggestions: [],
      focused: false,
      inputActive: false,
    };
  },

  watch: {
    localValue: {
      handler(newValue) {
        this.setMask(newValue);
      },
      deep: true,
    },
  },
  created() {
    const input = document.querySelector('#' + this.id);
    const suggestions = document.querySelector('#suggestions');
    document.addEventListener('click', (e) => {
      const withinInput = e.composedPath().includes(input);
      const withinSuggestions = e.composedPath().includes(suggestions);

      if (!withinInput && !withinSuggestions) {
        this.suggestions = [];
      }
    });
  },
  methods: {
    setMask(value) {
      let result = '';
      const oldValue = this.localValue;
      switch (this.type) {
        case 'phone':
          const p = value
            .replace(/\D/g, '')
            .match(/(\d{0,2})(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
          p[1] = '+7 (' + (p[1][1] !== undefined ? p[1][1] : '');
          result = !p[3]
            ? p[1] + p[2]
            : p[1] +
              p[2] +
              ') ' +
              p[3] +
              (p[4] ? '-' + p[4] : '') +
              (p[5] ? '-' + p[5] : '');
          this.localValue = result;
          if (p[5].length === 2) {
            this.$emit('onComplete');
          }
          break;
        case 'mobilePhone':
          const mp = value
            .replace(/\D/g, '')
            .match(/(\d{0,2})(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
          mp[1] = '+7 (9';
          result = !mp[3]
            ? mp[1] + mp[2]
            : mp[1] +
              mp[2] +
              ') ' +
              mp[3] +
              (mp[4] ? '-' + mp[4] : '') +
              (mp[5] ? '-' + mp[5] : '');
          this.localValue = result;
          if (mp[5].length === 2 && oldValue !== result) {
            this.$emit('onComplete');
          }
          break;

        case 'date':
        case 'pasportDate':
          const d = value
            .replace(/\D/g, '')
            .match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
          result = !d[2]
            ? d[1]
            : d[1] + '.' + (!d[3] ? d[2] : d[2] + '.' + d[3]);
          this.localValue = result;
          break;
        case 'expDate':
          const eD = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})/);
          result = !eD[2] ? eD[1] : eD[1] + '/' + (eD[2] ?? '');
          this.localValue = result;
          break;
        case 'creditCard':
          const cC = value
            .replace(/\D/g, '')
            .match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);
          result = !cC[2]
            ? cC[1]
            : cC[1] +
              '-' +
              (!cC[3] ? cC[2] : cC[2] + '-' + cC[3]) +
              (!cC[4] ? '' : '-' + cC[4]);
          this.localValue = result;
          break;
        case 'UMoney':
          const uM = value
            .replace(/\D/g, '')
            .match(/(\d{0,5})(\d{0,5})(\d{0,5})(\d{0,5})/);
          result = !uM[2]
            ? uM[1]
            : uM[1] +
              ' ' +
              (!uM[3] ? uM[2] : uM[2] + ' ' + uM[3]) +
              (!uM[4] ? '' : ' ' + uM[4]);
          this.localValue = result;
          break;
        case 'passport':
          const pass = value.replace(/\D/g, '').match(/(\d{0,4})(\d{0,6})/);
          result = !pass[2] ? pass[1] : pass[1] + '-' + pass[2];
          this.localValue = result;
          break;
        case 'issuerCode':
          const ic = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})/);
          result = !ic[2] ? ic[1] : ic[1] + '-' + ic[2];
          this.localValue = result;
          break;
        case 'smsCode':
          const sms = value.replace(/\D/g, '').match(/(\d{0,6})/);
          result = sms[1];
          this.localValue = result;
          break;
        default:
          break;
      }
      if (!this.suggest?.fromSuggest) {
        this.$emit('changeValue', this.localValue);
      }
    },

    async onChangeValue() {
      if (this.suggest?.request) {
        await DadataLibs.suggestions(
          this.localValue,
          this.suggest.request,
          this.suggest.locations,
          this.suggest.from_bound,
          this.suggest.to_bound,
          this.suggest.count
        )
          .then((res) => {
            if (res.data.suggestions) {
              let localSuggestions = [];
              if (this.suggest.fields) {
                res.data.suggestions.forEach((el) => {
                  if (
                    this.suggest.fields.every((value) =>
                      Object.keys(el.data).includes(value)
                    )
                  ) {
                    el.value = '';
                    this.suggest.fields.forEach((f, ind) => {
                      el.value +=
                        (el.data[f] ?? '') +
                        (ind + 1 === this.suggest.fields.length ? '' : ' - ');
                      if (el.value !== '') {
                        localSuggestions.push(el);
                      }
                    });
                  }
                });
              } else {
                localSuggestions = res.data.suggestions;
              }

              this.suggestions = localSuggestions;
            }
          })
          .catch(() => {
            this.suggestions = [];
          });

        if (!this.suggest?.fromSuggest) {
          this.$emit('changeValue', this.localValue);
        }
      } else {
        this.$emit('changeValue', this.localValue);
      }
    },
    chooseSuggestValue(value) {
      this.localValue = value;
      this.$emit('changeValue', this.localValue);
      this.$emit(
        'onSuggestionSelect',
        this.suggestions.filter((el) => el.value === value)
      );
      this.suggestions = [];
    },
    getColorOfSuggestion(value) {
      const eegExp = new RegExp(this.localValue.toLowerCase());
      const res = value.toLowerCase().match(eegExp);

      if (res !== null) {
        const before = value.substring(0, res.index);
        const query = value.substring(res.index, res.index + res[0].length);
        const after = value.substring(res.index + res[0].length, value.length);

        return [
          { value: before ?? '', colored: false },
          { value: query ?? '', colored: true },
          { value: after ?? '', colored: false },
        ];
      }
      return [{ value, colored: false }];
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/base/bootstrap-extended/_variables.scss';
.form-group {
  position: relative;
  @media (max-width: 744px) {
    margin-top: 0;
    margin-bottom: 0 !important;
  }
}
.c-input-group {
  .c-input {
    position: relative;
    .password-toggle {
      width: 29px;
      height: 58px;
      position: absolute;
      right: 0;
      top: 0;
      border: none;
      cursor: pointer;
      z-index: 2;
      background: 0 0;
      opacity: 1;
      transition: all 0.2s linear;
      @media only screen and (max-width: 1440px) {
        height: 48px;
        width: 24px;
      }
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transition: all 0.2s linear;
        background: url(@/assets/images/ocm/components/show-pass.svg) no-repeat
          center;
        background-size: contain;
      }
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transition: all 0.2s linear;
        opacity: 1;
        background: url(@/assets/images/ocm/components/hide-pass.svg) no-repeat
          center;
        background-size: contain;
      }
      &-active {
        &:after {
          opacity: 0;
        }
      }
    }

    &__label {
      width: 100%;
      white-space: nowrap;
      margin: 0;

      font-size: 20px;
      cursor: default;
      position: absolute;
      top: 20px;
      font-weight: 700;

      color: gray;
      z-index: 1;
      @media (max-width: 744px) {
        font-size: 16px;
      }

      &-active {
        top: 0;

        font-weight: 400;
        font-size: 15px !important;
        margin-top: -5px;
        @media (max-width: 744px) {
          font-size: 14px !important;
        }
      }
    }
    &__error {
      transition: 0.15s;
      width: 100%;
      color: $error;
      font-size: 14px;
      transform-origin: top;
      transform: scale(1, 0);
      transform: none !important;
      padding-top: 5px;
      padding-bottom: 5px;
      transform: scale(1, 1);
      overflow: hidden;
      line-height: 20px;
      @media only screen and (max-width: 390px) {
        font-size: 12px;
        line-height: 14px;
      }
      @media only screen and (max-width: 360px) {
        font-size: 10px;
        line-height: 12px;
      }
    }

    &__input {
      width: 100%;
      z-index: 2;
      position: relative;
      outline: none;
      border-radius: 0 !important;
      position: absolute;
      font-weight: 700;
      margin-top: 3px;
      font-family: 'ConsolaMonoBold', sans-serif;
      border: none;
      border-bottom: 1px solid $outline;
      background: 0 0;
      position: relative;
      z-index: 2;
      padding: 0;
      transition: all 0.4s ease-out;
      font-size: 20px;
      height: 54px;
      @media (max-width: 744px) {
        font-size: 16px;
        height: 50px;
        margin-top: 0;
      }

      &:focus {
        &::placeholder {
          color: rgba(0, 0, 0, 0.4);
        }
      }
      &-outline {
        border-color: $outline-blue !important;
      }

      &-failing {
        border-color: $error !important;
      }
    }
    &__suggestion-text {
      color: gray;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin: 9px 0 0;
    }
  }
  .suggestions {
    position: absolute;
    width: 100%;
    z-index: 999;

    border: 1px $outline solid;
    > div {
      background: $background;
      padding: 10px 5px;
      &:hover {
        background: $surface;
      }
      &:not(:last-child) {
        border-bottom: 0;
      }
    }
  }
}
</style>
