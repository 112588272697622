import axiosIns from '@/libs/axios'

export default class Dadata {
    static async clear(query, field) {
        return await axiosIns.get('/ext-services/dadata', {
            params: {
                q: query,
                field_type: field,
            },
        })
    }
}
