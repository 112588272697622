import {
    getLastRegIndex,
    getRegName,
    redirectToMain,
    sendGA4,
    sendLogs,
} from '@utils'
import Vue from 'vue'
import VueRouter from 'vue-router'
import oneClickMoney from './ocm/index'

const routes = [
    {
        path: '/',
        redirect: { name: 'landing' },
    },
    ...oneClickMoney,
    {
        path: '*',
        redirect: '/error-404',
    },
]
Vue.use(VueRouter)

const router = new VueRouter({
    base: process.env.BASE_URL,
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes,
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onComplete, onAbort) {
    const result = originalPush.call(this, location, onComplete, onAbort)
    if (result) {
        return result.catch(err => {
            console.warn(`consumed router exception: ${err}`)
        })
    }
    return result
}

router.beforeEach(async (to, _, next) => {
    const lastRegIndex = getLastRegIndex()
    if (lastRegIndex >= 4 && to.name !== 'application') {
        return next({ name: 'application' })
    }

    if (to.meta.pageTitle) {
        document.title = to.meta.pageTitle
    }

    if (localStorage.getItem('accountExist') != null) {
        redirectToMain({ log: 'redirect_already_registred', url: `https://oneclickmoney.ru${to.path}` })
    }

    // const loanWasTaken = getCookie('loanWasTaken')

    // if (loanWasTaken) {
    //     redirectToMain(to.fullPath)
    // }
    const developerMode = localStorage.getItem('developer_mode')

    if (to.query.developer_mode) {
        localStorage.setItem('developer_mode', true)
    }
    if (developerMode && to.query.developer_mode === 'false') {
        localStorage.removeItem('developer_mode')
    }
    const disableRedirect = localStorage.getItem('disableRedirect')

    if (to.meta.regIndex !== undefined) {
    // автоматическое перенаправление вперед до последней заполненной формы
    // if (lastRegIndex > to.meta.regIndex) {
    //     return next({ name: getRegName(lastRegIndex) })
    // }
        if (typeof lastRegIndex !== 'number' && to.meta.regIndex !== 0) {
            return next({ name: 'first' })
        }
        if (lastRegIndex + 1 < to.meta.regIndex) {
            return next({ name: getRegName(lastRegIndex + 1) })
        }
    }

    if (
        process.env.SEND_ANALYTICS !== 'false'
    && to.name === 'error-404'
    && disableRedirect !== 'true'
    ) {
        await sendLogs('landing', `redirect:${to.redirectedFrom}`)
        redirectToMain({
            url:
            'https://oneclickmoney.ru'
        + to.redirectedFrom
        + '?utm_source=ocm.oneclickmoney.ru&utm_term=redirect_back&utm_status=enable',
        })
        return
    }
    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(async to => {
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
    window.scrollTo(0, 0)

    if (
        process.env.SEND_ANALYTICS !== 'false'
    && localStorage.getItem('userIsInited')
    ) {
        const yandexMetrica = setInterval(() => {
            if (typeof window.ym !== 'undefined') {
                window.ym(process.env.YM_KEY, 'hit', to.name, {
                    params: {
                        title: to.meta.pageTitle,
                        referer: process.env.PROD_URL + to.fullPath,
                        utmTerm: localStorage.getItem('user_id'),
                        utmSource: localStorage.getItem('utmSource'),
                    },
                })
                clearInterval(yandexMetrica)
            }
        }, 500)
        await sendGA4(to.name, 'screen_load')
        await sendLogs('screen_load', 'screen_load: ' + to.name)
    }
})

export default router
