// eslint-disable-next-line object-curly-newline
import Logs from '@/api/logs'
import { useGtm } from '@gtm-support/vue2-gtm'

export const sendLogs = async (name, log = '') => {
    if (!localStorage.getItem('access_token')) {
        return
    }

    const urlParams = new URLSearchParams(window.location.search).get('lean_test_id')
    const redirect = new URLSearchParams(window.location.search).get('redirect')

    if (redirect === 'false') {
        localStorage.setItem('disableRedirect', true)
    } else if (redirect === 'true') {
        localStorage.removeItem('disableRedirect')
    }

    const part = localStorage.getItem('part')
    const disableRedirect = localStorage.getItem('disableRedirect')

    if (!urlParams && !part && disableRedirect !== 'true') {
        window.location.replace('https://oneclickmoney.ru')
    }

    if (process.env.SEND_ANALYTICS !== 'false' && localStorage.getItem('userIsInited')) {
        await Logs.send({
            access_token: localStorage.getItem('access_token'), name, log,
        })
    }
}
export const sendGA4 = async (name, log = '') => {
    const userIsInited = localStorage.getItem('userIsInited')

    if (process.env.SEND_ANALYTICS !== 'false' && userIsInited) {
        const userId = localStorage.getItem('user_id') ?? 0
        const leanTestId = localStorage.getItem('lean_test_id')
        const part = localStorage.getItem('part')

        useGtm()?.trackEvent({
            event: name,
            log,
            value: 1,
            cd1: `${leanTestId}_${part}`,
            cd2: userId,
        })
    }
}
export const redirectToMain = async ({ path, url, log }) => {
    useGtm()?.trackEvent({
        event: 'redirect',
        log: 'redirect_already_registred',
        value: 1,
        cd1: null,
        cd2: null,
    })
    const disableRedirect = localStorage.getItem('disableRedirect')

    if (disableRedirect === 'true') {
        return
    }

    const urlParams = new URLSearchParams(window.location.search).get('lean_test_id')
    const userIsInited = localStorage.getItem('userIsInited')

    const part = localStorage.getItem('part')
    if (!urlParams && !userIsInited) {
        window.location.replace('https://oneclickmoney.ru')
        return
    }

    if (process.env.SEND_ANALYTICS !== 'false') {
        const leanTestId = localStorage.getItem('lean_test_id')
        const userId = localStorage.getItem('user_id') ?? 0
        useGtm()?.trackEvent({
            event: 'redirect',
            log,
            value: 1,
            cd1: `${leanTestId}_${part ?? urlParams}`,
            cd2: userId,
        })
    }

    window.location.replace(url ?? (path ? ('https://oneclickmoney.ru' + path) : 'https://oneclickmoney.ru'))
}

export const unexistedRedirect = async (path, fullPath, blank = false) => {
    sendLogs('landing', `redirect:${fullPath ?? path}`)
    if (fullPath) {
        if (blank) {
            window.open(fullPath, '_blank')
        } else {
            window.location.replace(fullPath)
        }
        return
    }
    const disableRedirect = localStorage.getItem('disableRedirect')
    if (disableRedirect !== 'true') {
        if (blank) {
            window.open('https://oneclickmoney.ru' + path + '?utm_source=ocm.oneclickmoney.ru&utm_term=redirect_back&utm_status=enable', '_blank')
        } else {
            window.location.replace('https://oneclickmoney.ru' + path + '?utm_source=ocm.oneclickmoney.ru&utm_term=redirect_back&utm_status=enable')
        }
    }
}
